import axios from 'axios'
import { getDefaultOptions } from './auth'
import { getUrl } from './getUrl'
import { handleApi } from './handleApiCall'

export const listGroups = async () => {
	return await handleApi(axios.get<{ name: string }[]>(getUrl('list/groups'), getDefaultOptions()))
}

export const AddUserToGroup = async (uid: string, group_name: string) => {
	return await handleApi<{ failed: Array<any>; success: Array<any> }>(
		axios.post(getUrl(`user/add_one_user_to_group`), { uid, group_name }, getDefaultOptions())
	)
}

export const deleteSingleUserFromGroup = async (group_name: string, uid: string) => {
	return await handleApi<any>(axios.post(getUrl('user/batch/delete_single_user'), { group_name, uid }, getDefaultOptions()))
}
export const createGroup = async (name: string) => {
	return await handleApi<{ failed: Array<any>; success: Array<any> }>(axios.post(getUrl(`user/create_group`), { name }, getDefaultOptions()))
}
