import { addStudent } from 'API/course'
import { navigate, PageProps } from 'gatsby'
import { useCourseHook } from 'hooks/useCourseHook'
import { useGetUsersHook } from 'hooks/useGetUsersHook'
import React, { Fragment } from 'react'
import { AddUserToGroup } from 'API/group'
import { useState } from 'react'

const AddStudentToBatch = (_props: PageProps) => {
	const [search, setSearch] = useState<string>('')
	// const { course } = useCourseHook(_props.params.id)
	const { UsersTable } = useGetUsersHook({
		search,
		action_name: 'Add',
		action: async (user_id: string) => {
			const group_name = _props.params.id
			await AddUserToGroup(user_id, group_name)
			alert('User added to group')
		},
	})
	return (
		<Fragment>
			<input type='text' name='search' id='search' onChange={(e) => setSearch(e.target.value)} />

			{UsersTable}
		</Fragment>
	)
}

export default AddStudentToBatch
