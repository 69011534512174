import { getUserSearch } from 'API/user'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { UserType } from 'types/UserAdditionMetaData'

export const useGetUsersHook = ({ search, action_name, action }: { search: string; action_name?: string; action?: CallableFunction }) => {
	const [users, setUsers] = useState<UserType[]>()
	useEffect(() => {
		const run = async () => {
			if (search) {
				setUsers(await getUserSearch(search))
			}
		}
		run()
	}, [search])

	const UsersTable = users && (
		<>
			<table className='table'>
				<thead>
					<tr>
						<th scope='col'>#</th>
						<th scope='col'>First</th>
						<th scope='col'>Last</th>
						<th scope='col'>Email</th>
						<th scope='col'>Username</th>
						{action_name && <th scope='col'>{action_name}</th>}
					</tr>
				</thead>
				<tbody>
					{users.map((elem, index) => {
						return (
							<>
								<tr>
									<th scope='row'>{index + 1}</th>
									<td>{elem.first_name}</td>
									<td>{elem.last_name}</td>
									<td>{elem.email}</td>
									<td>{elem.username}</td>
									{action_name && (
										<td scope='col'>
											<Button
												onClick={() => {
													if (action) {
														action(elem.uid)
													}
												}}
											>
												{action_name}
											</Button>
										</td>
									)}
								</tr>
							</>
						)
					})}
				</tbody>
			</table>
		</>
	)

	return { users, setUsers, UsersTable }
}
